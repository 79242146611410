import React from "react"
import queryString from "query-string"
import { ThemeProvider } from "@material-ui/styles"
import { IntlProvider, addLocaleData } from "react-intl"
import { CssBaseline, NoSsr } from "@material-ui/core"
import { navigate, graphql } from "gatsby"
import localeConsts from "../../../constants/localeConsts"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import {
  Header,
  Footer,
  PaymentConfirComponent,
  CartProvider,
  MobileOrientation,
} from "../../../components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Theme from "../theme"

import LeafLeft from "../images/leaf left.png"
import LeafRight from "../images/leaf right.png"

import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"
import "../global_styles/global.css"

addLocaleData([...locale_en, ...locale_es])

const IndexPage = ({ data, pageContext, location }) => {
  const langKey = pageContext.locale
  const queryString = require("query-string")
  const parsedQuery = queryString.parse(location.search)
  const response = queryString.parse(location.search)
  const slug = pageContext.locale ? pageContext.slug : location.pathname
  let locale
  if (typeof window !== "undefined") {
    locale = localStorage.getItem("payULocale")
  }
  if (locale) {
    navigate(`/${locale}/${slug}/${location.search}`)
    localStorage.removeItem("payULocale")
  }
  const i18nmessages = require(`../messages/index/${
    langKey ? langKey : localeConsts.en
  }`)

  const {
    transitionTime,
    defaultTransitionTime,
  } = require("../../../constants/randomConsts")

  return (
    <PageTransition
      transitionTime={
        parsedQuery.transition ? transitionTime : defaultTransitionTime
      }
    >
      <IntlProvider
        locale={langKey ? langKey : localeConsts.en}
        messages={i18nmessages}
      >
        <React.Fragment>
          <MobileOrientation />
          <CssBaseline />
          <ThemeProvider theme={Theme}>
            <CartProvider cartId={undefined}>
              {langKey !== undefined && (
                <div>
                  <Header
                    data={data.header.edges[0].node}
                    slug={slug}
                    response={location.search}
                  />
                  <PaymentConfirComponent
                    location={location}
                    responseCode={response.polResponseCode}
                    referenceNum={response.reference_pol}
                    slug={slug}
                  />
                  <Footer
                    data={
                      langKey === localeConsts.es
                        ? data.footer.edges[1].node
                        : data.footer.edges[0].node
                    }
                    langKey={langKey}
                    bgImgLeft={LeafLeft}
                    bgImgRight={LeafRight}
                  />
                </div>
              )}
            </CartProvider>
          </ThemeProvider>
        </React.Fragment>
      </IntlProvider>
    </PageTransition>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
    header: allContentfulHeader {
      edges {
        node {
          shoppingCartLogo {
            file {
              url
            }
          }
          mainLogo {
            file {
              url
            }
          }
        }
      }
    }
    footer: allContentfulFooter {
      edges {
        node {
          copyright {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          footerMenuItems {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          newsletterEmail {
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            emailLable {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            buttonColor
            emailPlaceholder
          }
          socialMediaTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          discoverLogo {
            file {
              url
            }
          }
          amexLogo {
            file {
              url
            }
          }
          facebookLogo {
            file {
              url
            }
          }
          instagramLogo {
            file {
              url
            }
          }
          masterCardLogo {
            file {
              url
            }
          }
          payULogo {
            file {
              url
            }
          }
          visaLogo {
            file {
              url
            }
          }
          youTubeLogo {
            file {
              url
            }
          }
        }
      }
    }
  }
`
